import db from "./../../mock/localdb";
import { startWith, find, lookup, dateFrom, dateTo } from "../../mock/mockhelper";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, searchModel) {
    const statedReturns = {
      unanswered: [],
      unapproved: [],
      unreserved: [],
      notarrivaled: [],
      notshipped: [],
      cancel: [],
      rejected: [],
    };
    const filters = [];
    filters.push(startWith("productConditionType", 2));
    if (searchModel?.replacementStatus) {
      filters.push(startWith("replacementStatus", searchModel.replacementStatus));
    }
    if (searchModel?.createDatetimeFrom) {
      filters.push(dateFrom("createDatetime", searchModel.createDatetimeFrom));
    }
    if (searchModel?.createDatetimeTo) {
      filters.push(dateTo("createDatetime", searchModel.createDatetimeTo));
    }
    const purchaseReturns = await find(db.t_purchase_returns, filters, "returnCommitNumber");
    await Promise.all(
      purchaseReturns.map(async (p) => {
        [p.purchaseOrderDate, p.productCode, p.retailPrice, p.purchaseRate, p.purchaseQuantity, p.unitPrice] =
          await lookup(db.t_purchases, "purchaseId", p.purchaseId, [
            "purchaseOrderDate",
            "productCode",
            "retailPrice",
            "purchaseRate",
            "purchaseQuantity",
            "unitPrice",
          ]);
        [p.supplierCode, p.janCode, p.productName] = await lookup(db.t_products, "productCode", p.productCode, [
          "supplierCode",
          "janCode",
          "productName",
        ]);
        [p.maker] = await lookup(db.m_suppliers, "supplierCode", p.supplierCode, ["supplierName"]);

        switch (p.replacementStatus) {
          case 1:
            statedReturns.unanswered.push(p);
            break;
          case 2:
            statedReturns.unapproved.push(p);
            break;
          case 3:
            statedReturns.unreserved.push(p);
            break;
          case 4:
            statedReturns.notarrivaled.push(p);
            break;
          case 5:
            statedReturns.notshipped.push(p);
            break;
          case 6:
            statedReturns.cancel.push(p);
            break;
          case 7:
            statedReturns.rejected.push(p);
            break;
        }
      })
    );
    return { records: statedReturns };
  },
  async updateStatus({ rootGetters }, purchaseReturnModels) {
    let returnUpdateModels = [];
    for (let purchaseReturnModel of purchaseReturnModels) {
      const updateModel = {
        replacementStatus: purchaseReturnModel.replacementStatus,
        updateUser: rootGetters["security/displayname"],
        updateDatetime: new Date(),
      };

      if (updateModel.replacementStatus == 2) {
        updateModel.replacementExistence = true;
        updateModel.replacementQuantity = purchaseReturnModel.replacementQuantity;
      }
      if (updateModel.replacementStatus == 4) {
        updateModel.deliveryReservation = purchaseReturnModel.deliveryReservation;
        updateModel.deliveryCompany = purchaseReturnModel.deliveryCompany;
      }
      if (updateModel.replacementStatus == 5) {
        updateModel.inspectionResult = purchaseReturnModel.inspectionResult;
        updateModel.inspectionQuantity = purchaseReturnModel.inspectionQuantity;
      }
      if (updateModel.replacementStatus == 6) {
        updateModel.replacementCancelType = purchaseReturnModel.replacementCancelType;
      }

      await db.t_purchase_returns.update(purchaseReturnModel.rowId, updateModel);
      const purchaseReturn = await db.t_purchase_returns.get(purchaseReturnModel.rowId);
      [
        purchaseReturn.purchaseOrderDate,
        purchaseReturn.productCode,
        purchaseReturn.retailPrice,
        purchaseReturn.purchaseRate,
        purchaseReturn.purchaseQuantity,
      ] = await lookup(db.t_purchases, "purchaseId", purchaseReturn.purchaseId, [
        "purchaseOrderDate",
        "productCode",
        "retailPrice",
        "purchaseRate",
        "purchaseQuantity",
      ]);
      [purchaseReturn.supplierCode, purchaseReturn.janCode, purchaseReturn.productName, purchaseReturn.unitPrice] =
        await lookup(db.t_products, "productCode", purchaseReturn.productCode, [
          "supplierCode",
          "janCode",
          "productName",
          "unitPrice",
        ]);
      [purchaseReturn.maker] = await lookup(db.m_suppliers, "supplierCode", purchaseReturn.supplierCode, [
        "supplierName",
      ]);
      returnUpdateModels.push(purchaseReturn);
    }

    return { data: returnUpdateModels };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
