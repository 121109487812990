import * as Api from "../../libs/api-client";

const actions = {
  async search() {
    return Api.dispatchGet(`/category/search`);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
