import axios from "axios";
import VueStore from "../store/index";
import EndPoints from "../consts/EndPoints";
import { compress, decompress } from "./compressor";

const consts = Object.freeze({
  SUCCESS: 0,
  INVALID_PARAMETER: 1,
  BUSINESS_ERROR: 2,
  DATASIZE_ERROR: 3,
  ALREADY_CHANGED: 6,
  PERMISSION_DENIED: 7,
  ABEND: 9,
  PROCESSING: 10,
});

const isSuccessful = (header) => {
  return header?.resultCode === consts.SUCCESS;
};

const isBusinessError = (header) => {
  return header?.resultCode === consts.BUSINESS_ERROR;
};

const isAlreadyChanged = (header) => {
  return header?.resultCode === consts.ALREADY_CHANGED;
};

const isSystemError = (header) => {
  return !isSuccessful(header) && !isBusinessError(header) && !isAlreadyChanged(header);
};

export const client = axios.create({
  baseURL: process.env.VUE_APP_DELPHOI_API_URL,
  // headers: {
  //   common: {
  //     "Content-Type": "application/json",
  //   },
  // },
  responseType: "json",
  //timeout: 1000,
});

client.interceptors.request.use(
  (request) => {
    console.debug("request::" + request.url);
    console.debug(JSON.parse(JSON.stringify(request)));
    // console.info("[instance.interceptors.request.use]request", request);
    if (request.data?.compress !== false && request.data?.contents != null) {
      //console.time("[api-client]compress");
      request.data.contents = compress(request.data.contents);
      //console.timeEnd("[api-client]compress");
    }
    return request;
  },
  (error) => console.error("[instance.interceptors.request.use]error", error)
);

client.interceptors.response.use(
  (response) => {
    // console.info("[instance.interceptors.response.use]response", response);
    if (response.data?.compressed && response.data?.contents != null) {
      // console.time("[api-client]decompress");
      response.data.contents = decompress(response.data.contents);
      // console.timeEnd("[api-client]decompress");
    }
    console.debug("response::" + response.config.url);
    console.debug(JSON.parse(JSON.stringify(response)));
    return response;
  },
  (error) => {
    console.error("[instance.interceptors.response.use]error", error);
    throw error;
  }
);

export const createUrl = (path = "") => {
  const user = VueStore.getters["security/loggedInUser"];
  const base = EndPoints.fromType(user.corporateType);
  return `/${base}${path}`;
};

export const createBody = (body, header, compress = true) => {
  if (header == null) {
    header = {};
  }
  return { header: header, contents: body, compress };
};

export const pollingIdToken = async () => {
  await VueStore.dispatch("security/updateToken");
  while (sessionStorage.getItem("updatingIdToken").toLowerCase() === "true") {
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  if (sessionStorage.getItem("updatingIdToken").toLowerCase() === "error") {
    let e = new Error("auth error!!");
    e.response = { status: 900 };
    throw e;
  }
  return;
};

export const getIdToken = async () => {
  await pollingIdToken();
  return sessionStorage.getItem("setIdToken");
};

export const get = async (path, condition) => {
  return client.get(path, { headers: { Authorization: await getIdToken() }, params: condition });
};

export const post = async (path, contents, header = null) => {
  return client.post(path, createBody(contents, header), { headers: { Authorization: await getIdToken() } });
};

export const commonGet = async (path, condition) => {
  return client.get(`/${EndPoints.COMMON}${path}`, {
    headers: { Authorization: await getIdToken() },
    params: condition,
  });
};

export const commonPost = async (path, contents) => {
  return client.post(`/${EndPoints.COMMON}${path}`, createBody(contents), {
    headers: { Authorization: await getIdToken() },
  });
};

export const noAuthGet = (path, condition) => {
  return client.get(`/${EndPoints.NOAUTH}${path}`, { params: condition });
};

export const noAuthPost = (path, contents) => {
  return client.post(`/${EndPoints.NOAUTH}${path}`, createBody(contents));
};

export const dispatchGet = (path, condition, header = null) => {
  return get(createUrl(path), condition, header);
};

export const dispatchPost = (path, contents, header = null) => {
  console.log("dispatchPost:", header);
  return post(createUrl(path), contents, header);
};

export const statuses = {
  consts,
  isSuccessful,
  isBusinessError,
  isAlreadyChanged,
  isSystemError,
};
