import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/shipment/search", condition);
  },
  async inspect(_, condition) {
    return Api.dispatchPost("/transfer/inspect", condition);
  },
  async delete(_, shipmentModel) {
    return Api.dispatchPost("/shipment/delete", shipmentModel);
  },
  async handle(_, shipmentModel) {
    return Api.dispatchPost("/shipment/handle", shipmentModel);
  },
  async registShipno(_, shipmentModel) {
    return Api.dispatchPost("/shipment/regist-shipno", shipmentModel);
  },
  async updateShipno(_, condition) {
    return Api.dispatchPost("/shipment/update-shipno", condition);
  },
  async import(_, condition) {
    let response = RequestUtils.procBulkData(
      condition,
      (params) => {
        var payloadParam = {
          shipmentAchievements: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/shipment/bulk-payload", payloadParam);
      },
      (condition) => {
        let shippingModel = condition.contents;
        let header = condition.header;
        return Api.dispatchPost("/shipment/import", shippingModel, header);
      }
    );
    return response;
  },

  async bulkPayload(_, importModel) {
    return Api.dispatchPost("/shipment/bulk-payload", importModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/shipment/history", condition);
  },
  async shippinglabelDelete(_, shippinglabelModel) {
    return Api.dispatchPost("/shippinglabel/delete", shippinglabelModel);
  },
  async shippinglabelImport(_, shippinglabelModel) {
    let response = RequestUtils.procBulkData(
      shippinglabelModel,
      (params) => {
        var payloadParam = {
          shippingLabels: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/shippinglabel/import-bulk-payload", payloadParam);
      },
      (condition) => {
        let bulkModel = condition.contents;
        let header = condition.header;
        return Api.dispatchPost("/shippinglabel/import", bulkModel, header);
      }
    );
    return response;
  },
  async setResult(_, shippinglabelModel) {
    return Api.dispatchPost("/shipment/set-result", shippinglabelModel);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
