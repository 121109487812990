import * as Api from "./../../libs/api-client";

const actions = {
  async decode(_, condition) {
    return Api.commonGet("/history/decode", condition);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
