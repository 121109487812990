<template>
  <!-- エフトランスメニューは廃止。権限設計でカフェレオに含まれることになった。 -->
  <v-list dense>
    <v-list-item exact :to="{ name: 'Dashboard' }">
      <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
      <v-list-item-title>ダッシュボード</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-toy-brick">
      <template v-slot:activator>
        <v-list-item-title>商品案内</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ProductSearch' }">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>商品一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-database">
      <template v-slot:activator>
        <v-list-item-title>在庫管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'DeliveryScheduleSearch' }">
        <v-list-item-icon><v-icon>mdi-calendar-search</v-icon></v-list-item-icon>
        <v-list-item-title>入出荷予定一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ShippingInstructionSearch' }">
        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
        <v-list-item-title>出荷指示一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ArrivalAchievementSearch' }">
        <v-list-item-icon><v-icon>mdi-clipboard-text-search</v-icon></v-list-item-icon>
        <v-list-item-title>入荷実績一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ShipmentAchievementSearch' }">
        <v-list-item-icon><v-icon>mdi-clipboard-text-search</v-icon></v-list-item-icon>
        <v-list-item-title>出荷実績一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'FTStockMatching' }">
        <v-list-item-icon><v-icon>mdi-compare</v-icon></v-list-item-icon>
        <v-list-item-title>FT在庫突合</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-amazon">
      <template v-slot:activator>
        <v-list-item-title>Amazon専用</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'AmazonShippingInstruction' }">
        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
        <v-list-item-title>出荷指示</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-domain">
      <template v-slot:activator>
        <v-list-item-title>その他EDI</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'LoftCartonSearch' }">
        <v-list-item-icon><v-icon>mdi-database-search</v-icon></v-list-item-icon>
        <v-list-item-title>ロフトカートン予測一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-account">
      <template v-slot:activator>
        <v-list-item-title>ユーザ管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'UserSearch' }">
        <v-list-item-icon><v-icon>mdi-account-search</v-icon></v-list-item-icon>
        <v-list-item-title>ユーザ一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
  </v-list>
</template>

<script>
export default {
  name: "FtransMenu",
};
</script>
