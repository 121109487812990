<template>
  <v-card>
    <v-card-title class="text-h6"><v-icon>mdi-key</v-icon>パスワード変更</v-card-title>
    <v-card-text>
      <v-form ref="changeForm" v-model="validChangeForm" lazy-validation>
        <v-text-field
          :type="passwordModel.showCurrent ? 'text' : 'password'"
          :append-icon="passwordModel.showCurrent ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="passwordModel.showCurrent = !passwordModel.showCurrent"
          label="現在のパスワード"
          v-model="passwordModel.current"
          :rules="[rules.required, rules.password]"
        ></v-text-field>
        <v-text-field
          :type="passwordModel.showNewAndAgain ? 'text' : 'password'"
          :append-icon="passwordModel.showNewAndAgain ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="passwordModel.showNewAndAgain = !passwordModel.showNewAndAgain"
          label="新しいパスワード"
          v-model="passwordModel.new"
          :rules="[rules.required, rules.password]"
        ></v-text-field>
        <v-text-field
          :type="passwordModel.showNewAndAgain ? 'text' : 'password'"
          label="パスワードの再入力"
          v-model="passwordModel.again"
          :rules="[rules.required, rules.password2]"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSubmitClick">確定</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "PasswordChange",
  data() {
    return {
      passwordModel: {
        current: "",
        new: "",
        again: "",
        showCurrent: false,
        showNewAndAgain: false,
      },
      rules: {
        required: Validation.required,
        password: Validation.password,
        password2: (value) => this.passwordModel.new === value || "パスワードが一致しません",
      },
      validChangeForm: null,
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    init() {
      this.passwordModel = {
        current: "",
        new: "",
        again: "",
        showCurrent: false,
        showNewAndAgain: false,
      };
    },
    reset() {
      this.init();
      this.$refs.changeForm.resetValidation();
    },
    async onSubmitClick() {
      try {
        if (this.validate()) {
          this.loadingOn();
          var loginId = this.$store.getters["security/loggedInUser"].loginId;
          const response = await this.$store.dispatch("password/changePassword", {
            loginId: loginId,
            oldPassword: this.passwordModel.current,
            newPassword: this.passwordModel.new,
          });
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`パスワードが変更されました`, { timeout: 2300 });
              this.$emit("submit");
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              if (response.data?.header.messages) {
                var errorMessage = [];
                Object.keys(response.data.header.messages).forEach((key) => {
                  errorMessage.push(response.data.header.messages[key]);
                });
                this.$dialog.warning({
                  title: `パスワード変更`,
                  text: errorMessage.join("<br>"),
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("PasswordChange::onSubmitClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    validate() {
      const isValid = this.$refs.changeForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.changeForm.resetValidation();
      }
      return isValid;
    },
    onCancelClick() {
      this.$emit("cancel");
      this.reset();
      this.$refs.changeForm.resetValidation();
    },
  },
};
</script>
