import { createDefault } from "../utils/ConstUtils";

export default createDefault([
  { value: 0, text: "正常終了" },
  { value: 1, text: "パラメーターエラー" },
  { value: 2, text: "業務エラー" },
  { value: 3, text: "データサイズエラー" },
  { value: 6, text: "排他エラー" },
  { value: 7, text: "権限エラー" },
  { value: 8, text: "タイムアウト（時間超過エラー）" },
  { value: 9, text: "異常終了" },
  { value: 10, text: "処理中" },
]);
