<template>
  <v-container fluid>
    <v-row>
      <shortcut-menu icon="mdi-calendar-search" link="DeliveryScheduleSearch">入出荷予定一覧</shortcut-menu>
      <shortcut-menu icon="mdi-truck" link="ShippingInstructionSearch">出荷指示一覧</shortcut-menu>
      <shortcut-menu icon="mdi-truck" link="AmazonShippingInstruction">Amazon出荷指示</shortcut-menu>
      <shortcut-menu icon="mdi-clipboard-text-search" link="ArrivalAchievementSearch">入荷実績一覧</shortcut-menu>
      <shortcut-menu icon="mdi-clipboard-text-search" link="ShipmentAchievementSearch">出荷実績一覧</shortcut-menu>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12">
        <news-paper></news-paper>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12">
        <new-products></new-products>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
  </v-container>
</template>

<script>
import ShortcutMenu from "./common/ShortcutMenu.vue";
import NewsPaper from "./common/NewsPaper.vue";
import NewProducts from "./common/NewProducts.vue";

export default {
  name: "FtransDashboard",
  components: {
    ShortcutMenu,
    NewsPaper,
    NewProducts,
  },
};
</script>
