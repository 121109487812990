import moment from "moment";
import VueStore from "../store/index";

const required = (value) => {
  if (value != null) {
    if (String(value).trim().length > 0) {
      return true;
    }
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }
  }
  return "必須入力項目です";
};

const maxLength = (length) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    if (typeof value == "number") {
      if (String(value).length <= length) {
        return true;
      }
    } else {
      if (value.length <= length) {
        return true;
      }
    }
    return `${length}桁以下で入力してください`;
  };
};

const password = (value) => {
  if (value == null || value == "") {
    return true;
  }
  if (value.length >= 8) {
    let count = 0;
    if (value.match(/[a-zA-Z]/)) {
      count++;
    }
    if (value.match(/[0-9]/)) {
      count++;
    }
    if (value.match(/[!"#$%&'()*+-.,/:;<=>?@[\\\]^_`{|}~]/)) {
      count++;
    }
    if (count >= 2) {
      return true;
    }
  }
  return "英大小文字、数字、記号のうち最低2つ組み合わせた8文字以上である必要があります";
};

const phoneNo = (value) => {
  if (!value) {
    return true;
  }
  if (
    value.match(/^0([0-9]-[0-9]{4}|[0-9]{2}-[0-9]{3}|[0-9]{3}-[0-9]{2}|[0-9]{4}-[0-9])-[0-9]{4}$/) ||
    value.match(/^0[5789]0-[0-9]{4}-[0-9]{4}$/)
  ) {
    return true;
  }
  return "不正な形式です";
};

const mailAddress = (value) => {
  if (value == null) {
    return true;
  }
  if (
    value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return true;
  }
  return "不正な形式です";
};

const isZipcode = (value) => {
  if (!value) {
    return true;
  }
  if (value.match(/^[0-9]{3}-[0-9]{4}$/)) {
    return true;
  }
  return `不正な形式です`;
};

const isFileName = (value) => {
  if (value == null) {
    return true;
  }
  if (value.match(/[\\:/*<>?"|]/)) {
    return '「\\:/*<>?"|」は入力不可な文字です';
  }
  return true;
};

const isJancode = (value) => {
  if (!value) return true;
  if (value.length !== 8 && value.length !== 13) return "数字13桁又は8桁で入力してください";
  return true;
};
const isJancodeSearchList = (value) => {
  if (!value) return true;
  if (value.match(/[^0-9 ]/)) return "半角数値かスペースで入力してください。";
  return true;
};
const isPoSearchList = (value) => {
  if (!value) return true;
  if (value.match(/[^0-9a-zA-Z ]/)) return "半角英数字かスペースで入力してください。";
  return true;
};
const maxByteLength = (length) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    if (encodeURIComponent(value).replace(/%../g, "x").length <= length) {
      return true;
    }
    return `${length}byte以下で入力してください`;
  };
};

const maxByteLengthSjis = (length) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    var bytesize = 0;
    for (var i = 0; i < value.length; i++) {
      var c = value.charCodeAt(i);
      if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
        bytesize += 1;
      } else {
        bytesize += 2;
      }
    }
    if (bytesize <= length) {
      return true;
    }
    return `${length}byte以下で入力してください`;
  };
};

const isNumber = (value) => {
  if (value == null || value === "") {
    return true;
  }
  if (typeof value == "number") {
    return true;
  }
  if (value.match(/^([1-9]\d*|0)$/)) {
    return true;
  }
  return `数値(整数)を入力してください`;
};
const isCode = (value) => {
  if (value == null || value === "") {
    return true;
  }
  if (typeof value == "number") {
    return true;
  }
  if (value.match(/^([0-9]\d*)$/)) {
    return true;
  }
  return `数値を入力してください`;
};

const isDecimal = (value) => {
  if (value == null || value === "") {
    return true;
  }
  if (typeof value == "number") {
    return true;
  }
  if (value.match(/^([1-9]\d*|0)$/)) {
    return true;
  }
  // if (value.match(/^\d(\.\d+)?$/)) {
  if (value.match(/^-?[0-9]+\.[0-9]+$/)) {
    return true;
  }
  return `数値(小数)を入力してください`;
};

const isDate = (value) => {
  if (!value) return true;
  if (!moment(value, "YYYY/MM/DD", true).isValid() && !moment(value, "YYYY-MM-DD", true).isValid())
    return "YYYY/MM/DD形式で入力してください";
  return true;
};

const releaseDate = (value) => {
  if (value == null || value == "" || value == "未定") {
    return true;
  }
  if (moment(value, "YYYY/MM/DD", true).isValid() || moment(value, "YYYY-MM-DD", true).isValid()) return true;
  var replaceStr = value.replace(/-/g, "/");
  var splitStr = replaceStr.split("/");
  var year = parseInt(splitStr[0]);
  var month = parseInt(splitStr[1]) - 1;
  var day = splitStr[2];
  var targetDate = null;
  if (String(day) == "上" || String(day) == "中" || String(day) == "下" || String(day) == "予定") {
    targetDate = moment([year, month, 1]);
    if (moment(targetDate).isValid()) return true;
  }
  return `不正な形式です`;
};

const isMinNumber = (minValue) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    if (typeof value == "number" || value.match(/^([1-9]\d*|0)$/) || value.match(/^-?[0-9]+\.[0-9]+$/)) {
      if (value >= minValue) {
        return true;
      }
    }
    return `${minValue}以上で入力してください`;
  };
};

const isRate = (value) => {
  if (value == null) {
    return true;
  }
  if (typeof value == "number" || value.match(/^([1-9]\d*|0)$/) || value.match(/^-?[0-9]+\.[0-9]+$/)) {
    if (value >= 0) {
      return true;
    }
  }
  return `0以上で入力してください`;
};

const isTodayAfter = (value) => {
  if (value == null) return true;
  const date = moment(new Date());
  if (moment(new Date(value)).isSameOrBefore(date.add(-1, "days"))) return "当日日付より後の日付を指定してください";
  return true;
};

const isNextdayAfter = (value) => {
  if (value == null) return true;
  const date = moment(new Date());
  if (moment(new Date(value)).isSameOrBefore(date)) return "翌日日付より後の日付を指定してください";
  return true;
};
const isNumberMinusAllowed = (value) => {
  if (value == null || value === "") {
    return true;
  }
  if (typeof value == "number") {
    return true;
  }
  if (value.match(/^[-]?([1-9]\d*|0)$/)) {
    return true;
  }
  return `数値(整数)を入力してください`;
};
const isCsv = (value) => {
  if (value == null || value === "") {
    return true;
  }
  var filename = value.name;
  const pos = filename.lastIndexOf(".");
  if (pos === -1) return "";
  if (filename.slice(pos + 1) == "csv") return true;
  return "CSVのみアップロード可能です";
};

const isExcel = (value) => {
  if (value == null || value === "") {
    return true;
  }
  var filename = value.name;
  const pos = filename.lastIndexOf(".");
  if (pos === -1) return "";
  var ext = filename.slice(pos + 1);
  if (ext == "xls" || ext == "xlsx" || ext == "xlsm") return true;
  return "エクセルファイルのみアップロード可能です";
};

const maxLengthMinusAllowed = (length) => {
  return (value) => {
    if (value == null || value === "") {
      return true;
    }
    if (typeof value == "number") {
      if (String(value).charAt(0) == "-") {
        value = value.replace(/-/g, "");
      }
      if (String(value).length <= length) {
        return true;
      }
    } else {
      if (String(value).charAt(0) == "-") {
        value = value.replace(/-/g, "");
      }
      if (value.length <= length) {
        return true;
      }
    }
    return `${length}桁以下で入力してください`;
  };
};

const maxLengthTrimComma = (length) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    if (typeof value == "number") {
      if (String(value).replace(/,/g, "").length <= length) {
        return true;
      }
    } else {
      if (value.replace(/,/g, "").length <= length) {
        return true;
      }
    }
    return `${length}桁以下で入力してください`;
  };
};

const maxRateLength = (beforeLength, afterLength) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    let ret = true;
    let argValue = null;
    if (typeof value == "number") {
      argValue = String(value).split(".");
    } else {
      argValue = value.split(".");
    }
    if (argValue[0].length > beforeLength) {
      ret = `整数部は${beforeLength}桁までです。`;
    }
    if (argValue.length > 1 && argValue[1].length > afterLength) {
      ret = `少数部は${afterLength}桁までです。`;
    }
    return ret;
  };
};

const deliveryTime = (value) => {
  if (value == null || value == "") {
    return true;
  }
  if (moment(value, "YYYY/MM/DD", true).isValid() || moment(value, "YYYY-MM-DD", true).isValid()) return true;
  var replaceStr = value.replace(/-/g, "/");
  var splitStr = replaceStr.split("/");
  var year = parseInt(splitStr[0]);
  var month = parseInt(splitStr[1]) - 1;
  var day = splitStr[2];
  var targetDate = null;
  if (String(day) == "上" || String(day) == "中" || String(day) == "下" || String(day) == "予定") {
    targetDate = moment([year, month, 1]);
    if (moment(targetDate).isValid()) return true;
  }
  return `不正な形式です`;
};

const rangeOfNumber = (start, stop = null) => {
  return (value) => {
    if (value == null) {
      return true;
    }
    let ret = true;
    let errorMessage = "";
    if (start !== null) {
      errorMessage = `${start}以上`;
    }
    if (stop !== null) {
      errorMessage += `${stop}以下`;
    }
    errorMessage += "の範囲で指定してください。";
    if (start !== null && value < start) {
      ret = errorMessage;
    }
    if (stop !== null && value > stop) {
      ret = errorMessage;
    }
    return ret;
  };
};

const isDeliveryZipArea = async (value) => {
  if (value == null || value == "") return true;
  let res = await VueStore.dispatch("deliveryArea/isZipCode", {
    zipCode: value,
  });
  if (!res.data.contents.isZipCode) return "配送エリアマスタに存在する郵便番号で入力してください";
  return true;
};

const isBoolean4Import = (value) => {
  if (value == null || value == "") return true;
  if (value != 0 && value != 1) return "0または1の形式で入力してください";
  return true;
};

const isCustomer = async (value) => {
  if (value == null || value == "") return true;
  let res = await VueStore.dispatch("customer/search", {
    customerCode: value,
  });
  if (res.data.contents.customers.length == 1) {
    return true;
  } else {
    return "取引先マスタに存在する取引先CDを入力してください";
  }
};

const isMultiple = (factor, factorName) => {
  return (value) => {
    if (value == null || value == "") return true;
    if (Number(value) % Number(factor) !== 0) return `${factorName}(${factor})の倍数を入力してください`;
    return true;
  };
};

export default {
  required,
  maxLength,
  password,
  phoneNo,
  mailAddress,
  maxByteLength,
  isNumber,
  isDecimal,
  isDate,
  releaseDate,
  isZipcode,
  isJancode,
  isJancodeSearchList,
  isPoSearchList,
  isFileName,
  maxByteLengthSjis,
  isMinNumber,
  isRate,
  isTodayAfter,
  isNextdayAfter,
  isCode,
  isNumberMinusAllowed,
  isCsv,
  isExcel,
  maxLengthMinusAllowed,
  maxLengthTrimComma,
  maxRateLength,
  deliveryTime,
  rangeOfNumber,
  isDeliveryZipArea,
  isBoolean4Import,
  isCustomer,
  isMultiple,
};
