<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
};
</script>
<style lang="scss">
.ag-header-cell-text {
  font-size: 10px !important;
}
.ag-cell-value {
  font-size: 10px !important;
}
</style>
