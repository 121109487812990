import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet("/invoice/search", condition);
  },
  async update(_, condition) {
    return Api.dispatchPost("/invoice/update", condition);
  },
  async cancel(_, condition) {
    return Api.dispatchPost("/invoice/cancel", condition);
  },
  async print(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/invoice/print", bulkModel, header);
    });
    return response;
  },
  async history(_, condition) {
    return Api.dispatchGet("/invoice/history", { invoiceNumber: condition });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
