<template>
  <v-card>
    <template v-for="(banner, index) in banners">
      <v-row :key="banner.bannerId" no-gutters>
        <v-col cols="12" v-html="banner.contents"></v-col>
      </v-row>
      <v-divider v-if="index != banners.length - 1" :key="index" class="mb-1"></v-divider>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "NewProducts",
  props: ["banners"],
};
</script>
