import * as Api from "./../../libs/api-client";

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/stock/search", condition);
  },
  async update(_, stockModel) {
    return Api.dispatchPost("/stock/update", stockModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/stock/history", condition);
  },
  async storageSearch(_, condition) {
    return Api.dispatchGet("/stock/storage-search", condition);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
