import * as Api from "../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const mutations = {};

const actions = {
  async search() {
    return Api.dispatchGet(`/stockmatch/search`);
  },
  async create(_, corporationModel) {
    return Api.dispatchPost("/stockmatch/create", corporationModel);
  },
  async update(_, corporationModel) {
    return Api.dispatchPost("/stockmatch/update", corporationModel);
  },
  async import(_, importModel) {
    const response = RequestUtils.procBulkData(
      importModel,
      (params) => {
        const payloadParam = {
          logisticsStockImport: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/stockmatch/bulk-payload", payloadParam);
      },
      (condition) => {
        const contents = condition.contents;
        const header = condition.header;
        return Api.dispatchPost("/stockmatch/import", contents, header);
      }
    );
    return response;
  },
  async remove(_, corporationModel) {
    return Api.dispatchPost("/stockmatch/delete", corporationModel);
  },
};

export default {
  namespaced: true,
  mutations,
  actions,
};
