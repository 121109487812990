import VueStore from "../store/index";

export const valueOf = (items, value, field = "value", pickup = "text") => {
  const found = items.find((item) => item[field] == value);
  return found ? found[pickup] : null;
};

export const createDefault = (items, callback) => {
  const constant = {};
  items.forEach((item) => {
    if (item.enum && item.enum.length > 0) {
      constant[item.enum] = item.value;
    }
  });
  constant.all = (hidden) => {
    return hidden ? items : items.filter((item) => !item.hidden);
  };
  constant.of = (value) => valueOf(items, value);
  constant.textOf = (text) => valueOf(items, text, "text", "value");
  if (typeof callback === "function") {
    callback(constant, items);
  }
  constant.refDataArray = (hidden) => {
    let ret = "";
    let tmpRet = hidden ? items : items.filter((item) => !item.hidden);
    tmpRet.forEach((tmp, index, array) => {
      ret += '"' + tmp.value + '"' + ':"' + tmp.text + '"';
      if (index != array.length - 1) {
        ret += ",";
      }
    });
    ret = "{" + ret + "}";

    return JSON.parse(ret);
  };
  Object.freeze(constant);
  return constant;
};

// 既存の定数定義用に作成（新規はserviceSettingで良い）
export const createServiceSetting = (items) => {
  const serviceStting = VueStore.getters["security/serviceSetting"];
  const constant = {};
  Object.keys(items).forEach(function (key) {
    if (typeof serviceStting[items[key]] === "undefined") {
      throw new Error();
    }
    constant[key] = serviceStting[items[key]];
  });
  Object.freeze(constant);
  console.log("createServiceSetting::");
  console.log(constant);
  return constant;
};

export const serviceSetting = () => {
  return VueStore.getters["security/serviceSetting"];
};
