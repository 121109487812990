import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/shippinginstruct/search", condition);
  },
  async outShippinginstruct(_, condition) {
    let shippingInstructionModel = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/shippinginstruct/out-shippinginstruct", shippingInstructionModel, header);
  },
  async update(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/shippinginstruct/update", bulkModel, header);
    });
    return response;
  },
  async confirm(_, condition) {
    let shippingInstructionModel = condition.contents;
    let header = condition.header;
    return Api.dispatchPost("/shippinginstruct/confirm", { shipments: shippingInstructionModel }, header);
  },
  async cancel(_, shippingInstructionModel) {
    return Api.dispatchPost("/shippinginstruct/cancel", shippingInstructionModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/shippinginstruct/history", condition);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
