import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async history(_, condition) {
    return Api.dispatchGet("/deficiency/history", { deficiencyNumber: condition });
  },
  async search(_, condition) {
    return Api.dispatchPost("/deficiency/search", condition);
  },
  async searchMaker(_, condition) {
    const statedDeficiency = {
      unanswered: [],
      unapproved: [],
      unreserved: [],
      notarrivaled: [],
      notshipped: [],
      cancel: [],
    };
    let response = await Api.dispatchGet("/deficiency/search", condition);
    if (response.data.contents?.deficiencies) {
      response.data.contents.deficiencies.map(async (p) => {
        switch (p.deficiencyStatus) {
          case 1:
            statedDeficiency.unanswered.push(p);
            break;
          case 2:
            statedDeficiency.unapproved.push(p);
            break;
          case 3:
            statedDeficiency.unreserved.push(p);
            break;
          case 4:
            statedDeficiency.notarrivaled.push(p);
            break;
          case 5:
            statedDeficiency.notshipped.push(p);
            break;
          case 6:
            statedDeficiency.cancel.push(p);
            break;
        }
      });
      response.data.contents.statedDeficiency = statedDeficiency;
    }
    return response;
  },
  async entry(_, condition) {
    return Api.dispatchPost("/deficiency/create", condition);
  },
  async update(_, condition) {
    return Api.dispatchPost("/deficiency/update", condition);
  },
  async cancel(_, condition) {
    return Api.dispatchPost("/deficiency/cancel", condition);
  },
  async approval(_, condition) {
    return Api.dispatchPost("/deficiency/approval", condition);
  },
  async replacementquantity(_, condition) {
    return Api.dispatchPost("/deficiency/set-replacementquantity", condition);
  },
  async deliveryreservation(_, condition) {
    return Api.dispatchPost("/deficiency/set-deliveryreservation", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
