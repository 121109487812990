import * as Api from "./../../libs/api-client";

const actions = {
  async reissueRequest(_, inputModel) {
    return Api.noAuthGet("/user/get-user-info", inputModel);
  },
  async checkAuthenticationCode(_, inputModel) {
    return Api.noAuthGet("/user/comfirm-user", inputModel);
  },
  async changePassword(_, inputModel) {
    return Api.commonPost("/user/update-password", inputModel);
  },
  async reissuePassword(_, inputModel) {
    return Api.noAuthPost("/user/reissue-password", inputModel);
  },
};

export default {
  namespaced: true,
  actions,
};
