import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/customer/search`, condition);
  },
  async suggest(_, condition) {
    return Api.dispatchGet(`/customer/suggest`, condition);
  },
  async suggestPost(_, condition) {
    return Api.dispatchPost(`/customer/suggest`, condition);
  },
  async overseasImpossible() {
    return Api.dispatchGet(`/customer/overseas-impossible`);
  },
  async tohoLimited() {
    return Api.dispatchGet(`/customer/toho-limited`);
  },
  async outAddress(_, condition) {
    return Api.dispatchPost(`/customer/out-address`, condition);
  },
  async create(_, customerModel) {
    return Api.dispatchPost("/customer/create", customerModel);
  },
  async update(_, customerModel) {
    return Api.dispatchPost("/customer/update", customerModel);
  },
  async remove(_, customerModel) {
    return Api.dispatchPost("/customer/delete", customerModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/customer/history", { customerCode: condition });
  },
  async import(_, importModel) {
    return Api.dispatchPost("/customer/import", importModel);
  },
  async getCustomerName(_, condition) {
    return Api.commonGet("/customer/get-customername", { customerCd: condition });
  },
  async updatePic(_, condition) {
    let response = RequestUtils.procBulkData2(condition, (condition) => {
      let bulkModel = condition.contents;
      let header = condition.header;
      return Api.dispatchPost("/customer/update-pic", bulkModel, header);
    });
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
