import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    const statedReturns = {
      unapproved: [],
      notshipped: [],
      uninspected: [],
      inspected: [],
      cancel: [],
    };
    let response = await Api.dispatchPost("/purchasereturn/search", { searchConditions: condition });
    if (response.data.contents.purchaseReturns) {
      response.data.contents.purchaseReturns.map(async (p) => {
        RequestUtils.pushPurchaseReturn(p, statedReturns);
      });
    }
    response.data.contents.statedReturns = statedReturns;

    return response;
  },
  async history(_, condition) {
    return Api.dispatchGet("/purchasereturn/history", { returnCommitNumber: condition });
  },
  async purchasSearch(_, condition) {
    return Api.dispatchPost("/purchase/search", condition);
  },
  async entry(_, condition) {
    return Api.dispatchPost("/purchasereturn/create", condition);
  },
  async update(_, condition) {
    return Api.dispatchPost("/purchasereturn/update", condition);
  },
  async cancel(_, condition) {
    return Api.dispatchPost("/purchasereturn/cancel", condition);
  },
  async approval(_, condition) {
    return Api.dispatchPost("/purchasereturn/approval", condition);
  },
  async result(_, condition) {
    return Api.dispatchPost("/purchasereturn/set-result", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
