<template>
  <v-list dense>
    <v-list-item exact :to="{ name: 'Dashboard' }">
      <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
      <v-list-item-title>ダッシュボード</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-toy-brick" v-if="allowedAction(['V010101'])">
      <template v-slot:activator>
        <v-list-item-title>商品案内</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ProductRegisteredSearch' }" v-if="allowedAction(['V010101'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>商品一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-note" v-if="allowedAction(['V020101', 'V020201', 'V020301', 'V020401'])">
      <template v-slot:activator>
        <v-list-item-title>購買管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'OrderSearch' }" v-if="allowedAction(['V020101'])">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>発注一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'OrderAchievementSearch' }" v-if="allowedAction(['V020201'])">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>入荷済み商品一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ForecastSearch' }" v-if="allowedForcast">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>フォーキャスト一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'OrderReturnSearch' }" v-if="allowedAction(['V020401'])">
        <v-list-item-icon><v-icon>mdi-note-search</v-icon></v-list-item-icon>
        <v-list-item-title>返品一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-account" v-if="allowedAction(['V030101'])">
      <template v-slot:activator>
        <v-list-item-title>ユーザ管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'UserSearch' }" v-if="allowedAction(['V030101'])">
        <v-list-item-icon><v-icon>mdi-account-search</v-icon></v-list-item-icon>
        <v-list-item-title>ユーザ一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group no-action prepend-icon="mdi-store" v-if="allowedAction(['V030201', 'V030301', 'V030401'])">
      <template v-slot:activator>
        <v-list-item-title>店舗管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'CorporationEdit' }" v-if="allowedAction(['V030201'])">
        <v-list-item-icon><v-icon>mdi-database-edit</v-icon></v-list-item-icon>
        <v-list-item-title>法人情報</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'CustomerSearch' }" v-if="allowedAction(['V030301'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>店舗一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'DeliveryDestinationSearch' }" v-if="allowedAction(['V030401'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>配送先一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
  </v-list>
</template>

<script>
import VendorViewMixin from "../../mixins/vendorView";

export default {
  name: "VendorMenu",
  mixins: [VendorViewMixin],
  computed: {
    allowedForcast() {
      return this.allowedAction("V020301") && this.$store.getters["security/loggedInUser"].allowForecast == "y"
        ? true
        : false;
    },
  },
};
</script>
