import * as Api from "../../libs/api-client";

const actions = {
  async search(_, condition) {
    return Api.dispatchGet("/forcast/search", condition);
  },
  async postSearch(_, condition) {
    return Api.dispatchPost("/forcast/search", condition);
  },
  async detail(_, condition) {
    return Api.dispatchGet("/forcast/search-allocation-orders", condition);
  },
  async assign(_, assignModel) {
    return Api.dispatchPost("/forcast/assign", assignModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/forcast/history", condition);
  },
  async importAssignment(_, importAssignModel) {
    return Api.dispatchPost("/forcast/import-assignment", importAssignModel);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
