import { createDefault } from "../utils/ConstUtils";

const cafereoPrivileges = [
  { value: 101, text: "作業者" },
  { value: 102, text: "承認者" },
];
export const CafereoPrivileges = createDefault(cafereoPrivileges);

const makerPrivileges = [
  { value: 201, text: "作業者" },
  { value: 202, text: "管理者" },
];
export const MakerPrivileges = createDefault(makerPrivileges);

const vendorPrivileges = [
  { value: 301, text: "作業者" },
  { value: 302, text: "管理者" },
];
export const VendorPrivileges = createDefault(vendorPrivileges);

export default createDefault([{ value: 0, text: "-" }, ...cafereoPrivileges, ...makerPrivileges, ...vendorPrivileges]);
