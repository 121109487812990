import axios from "axios";
import FileUtils from "./../../utils/FileUtils";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async imageUpload(_, param) {
    let filesIdx = 0;
    for (let i = 0; i < param.productImages.length; i++) {
      if (param.productImages[i].url) {
        const url = param.productImages[i].url;
        const buffer = await FileUtils.readAsArrayBuffer(param.files[filesIdx]);
        await axios.put(url, buffer, { headers: { "Content-Type": param.files[filesIdx].type } });
        filesIdx++;
        console.log("url:", url);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
