import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/supplier/search`, condition);
  },
  async suggest(_, condition) {
    return Api.dispatchGet(`/supplier/suggest`, condition);
  },
  async create(_, corporationModel) {
    return Api.dispatchPost("/supplier/create", corporationModel);
  },
  async update(_, corporationModel) {
    return Api.dispatchPost("/supplier/update", corporationModel);
  },
  async remove(_, corporationModel) {
    return Api.dispatchPost("/supplier/delete", corporationModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/supplier/history", { supplierCode: condition });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
