import * as Api from "./../../libs/api-client";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet(`/conversion/search`, condition);
  },
  async create(_, conversionModel) {
    return Api.dispatchPost("/conversion/create", conversionModel);
  },
  async update(_, conversionModel) {
    return Api.dispatchPost("/conversion/update", conversionModel);
  },
  async remove(_, conversionModel) {
    return Api.dispatchPost("/conversion/delete", conversionModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/conversion/history", { conversionCode: condition });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
