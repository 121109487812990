let length = 0;

export const getItem = (key) => {
  console.log("getItem:", key);
  return sessionStorage.getItem(key);
};

export const setItem = (key, val) => {
  console.log("setItem:", key, val);
  sessionStorage.setItem(key, val);
  length++;
};

export const removeItem = (key) => {
  console.log("removeItem:", key);
  sessionStorage.removeItem(key);
  length--;
};

export const key = (idx) => {
  console.log("key:", idx);
  return sessionStorage.key(idx);
};

export const clear = () => {
  console.log("clear:", key);
  sessionStorage.clear();
  length = 0;
};

export default { length, getItem, setItem, removeItem, key, clear };
