import * as Api from "./../../libs/api-client";
import RequestUtils from "./../../utils/RequestUtils";

const actions = {
  async search(_, condition) {
    return Api.dispatchPost("/orders/search", condition);
  },
  async entry(_, cartModel) {
    return Api.dispatchPost("/orders/create", cartModel);
  },
  async update(_, orderModel) {
    return Api.dispatchPost("/orders/update", orderModel);
  },
  async cancel(_, order) {
    return Api.dispatchPost("/orders/cancel", order);
  },
  async split(_, backOrderModel) {
    return Api.dispatchPost("/orders/split", backOrderModel);
  },
  async assign(_, assignModel) {
    return Api.dispatchPost("/orders/assign", assignModel);
  },
  async approval(_, approvalModel) {
    return Api.dispatchPost("/orders/approval", approvalModel);
  },
  async rejected(_, rejectedModel) {
    return Api.dispatchPost("/orders/rejected", rejectedModel);
  },
  async searchOrderAcceptedProduct(_, condition) {
    return Api.dispatchGet("/product/search-order-accepted", condition);
  },
  async outDeliveryNote(_, condition) {
    return Api.dispatchPost("/ordersshipped/out-delivery-note", condition);
  },
  async ordersshippedSearch(_, condition) {
    return Api.dispatchPost("/ordersshipped/search", condition);
  },
  async history(_, condition) {
    return Api.dispatchGet("/orders/history", condition);
  },
  async import(_, importModel) {
    return Api.dispatchPost("/orders/import", importModel);
  },
  async importBatch(_, importModel) {
    const response = RequestUtils.procBulkData(
      importModel,
      (params) => {
        const payloadParam = {
          cart: params.rows[0],
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/orders/bulk-payload", payloadParam);
      },
      (condition) => {
        const contents = condition.contents;
        const header = condition.header;
        return Api.dispatchPost("/orders/import", contents, header);
      }
    );
    return response;
  },
  async trackingInfo(_, condition) {
    return Api.dispatchGet("/tracking/search", condition);
  },
  async searchOrderAcceptedCustomer(_, condition) {
    return Api.dispatchGet("/customer/search-order-accepted", condition);
  },
  async ordersshippedHistory(_, condition) {
    return Api.dispatchGet("/ordersshipped/history", condition);
  },
  async searchPurchase(_, condition) {
    return Api.dispatchPost("/purchase/search", condition);
  },
  async splitRemaining(_, backOrderModel) {
    return Api.dispatchPost("/orders/split-remaining", backOrderModel);
  },
  async bulkupdate(_, condition) {
    const response = RequestUtils.procBulkData(
      condition,
      (params) => {
        const payloadParam = {
          orders: params.rows,
          bulkId: params.bulkId,
        };
        return Api.dispatchPost("/orders/bulk-payload-bulkupdate", payloadParam);
      },
      (condition) => {
        const contents = condition.contents;
        const header = condition.header;
        return Api.dispatchPost("/orders/bulkupdate", contents, header);
      }
    );
    return response;
  },
  async updateSlipDate(_, condition) {
    return Api.dispatchPost("/ordersshipped/update-slip-date", condition);
  },
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
};
