import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async searchDelivery(_, condition) {
    return Api.dispatchGet(`/delivery/search`, condition);
  },
  async create(_, deliveryModel) {
    return Api.dispatchPost("/delivery/create", deliveryModel);
  },
  async import(_, deliveryModel) {
    return Api.dispatchPost("/delivery/import", deliveryModel);
  },
  async update(_, deliveryModel) {
    return Api.dispatchPost("/delivery/update", deliveryModel);
  },
  async remove(_, deliveryModel) {
    return Api.dispatchPost("/delivery/delete", deliveryModel);
  },
  async history(_, condition) {
    return Api.dispatchGet("/delivery/history", { deliveryCode: condition });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
