import * as Api from "./../../libs/api-client";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async search(_, condition) {
    return Api.dispatchGet("/billings/search", condition);
  },
  async invoice(_, condition) {
    return Api.dispatchPost("/invoice/create", condition);
  },
  async history(_, condition) {
    return Api.dispatchGet("/billings/history", { billingNumber: condition });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
