import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isCafereoUser: "security/isCafereo",
      isFtransUser: "security/isFtrans",
      isMakerUser: "security/isMaker",
      isVendorUser: "security/isVendor",
      globalPageSize: "ui/defaultPageSize",
      gridHeightSize: "ui/gridHeightSize",
    }),
  },
  methods: {
    setTitle(title) {
      if (title) {
        document.title = `${process.env.VUE_APP_SYSTEM_NAME} | ${title}`;
      } else {
        document.title = `${process.env.VUE_APP_SYSTEM_NAME}`;
      }
    },
    loadingOn(message) {
      this.$store.commit("ui/loading", { enabled: true, message });
    },
    loadingOff() {
      this.$store.commit("ui/loading", { enabled: false });
    },
    allowedAction(...actions) {
      const allowedActions = this.$store.getters["security/userAllowedAction"];
      const _allowedAction = (...actions) => {
        return actions.some((action) => {
          if (Array.isArray(action)) {
            return _allowedAction(...action);
          } else {
            return allowedActions.includes(action);
          }
        });
      };
      const allowed = _allowedAction(...actions);
      // console.log("AllowedAction", actions, allowed);
      return allowed;
    },
    redirectError() {
      this.loadingOff();
      this.$router.push({ name: "GlobalError" });
    },
    handleResize: function () {
      let size = "";
      if (this.$store.getters["ui/defaultPageSize"] > 10) {
        size = window.innerHeight - 145;
      } else {
        size = window.innerHeight - 195;
      }
      this.$store.commit("ui/gridResize", size);
    },
    async apiRequestError(error) {
      const response = error.response;
      if (!response) {
        this.$dialog.error({
          title: `通信エラー`,
          text: `アクセスが制限されました。`,
          actions: ["OK"],
        });
      } else {
        if (response.data?.maintenance) {
          return this.redirectMaintenance();
        }
        // NOTE:caseが使えないのでif使用
        const status = error.response?.status;
        if (status == 504) {
          this.$dialog.error({
            title: `通信エラー`,
            text: `タイムアウトしました。`,
            actions: ["OK"],
          });
        } else if (status == 900) {
          let ok = await this.$dialog.error({
            title: `認証エラー`,
            text: `ログイン有効期限が切れました。再度ログインしてください。`,
            actions: ["OK"],
          });
          if (ok) {
            this.$router.push({ name: "Login" });
          }
        } else {
          return this.redirectError();
        }
      }
    },
    redirectMaintenance() {
      this.loadingOff();
      this.$router.push({ name: "Maintenance" }, () => {});
    },
  },
};
