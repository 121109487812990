<template>
  <v-list dense>
    <v-list-item exact :to="{ name: 'Dashboard' }">
      <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
      <v-list-item-title>ダッシュボード</v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-group
      no-action
      prepend-icon="mdi-toy-brick"
      v-if="allowedAction(['M010101', 'M010201', 'M010301', 'M010401'])"
    >
      <template v-slot:activator>
        <v-list-item-title>商品案内</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'ProductEntry' }" v-if="allowedAction(['M010101'])">
        <v-list-item-icon><v-icon>mdi-pencil-plus-outline</v-icon></v-list-item-icon>
        <v-list-item-title>新商品登録</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ProductImageEntry' }" v-if="allowedAction(['M010201'])">
        <v-list-item-icon><v-icon>mdi-image</v-icon></v-list-item-icon>
        <v-list-item-title>新商品画像登録</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ProductSearch' }" v-if="allowedAction(['M010301'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>登録商品一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'ProductImageSearch' }" v-if="allowedAction(['M010401'])">
        <v-list-item-icon><v-icon>mdi-image</v-icon></v-list-item-icon>
        <v-list-item-title>登録商品画像一覧</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group
      no-action
      prepend-icon="mdi-book-open"
      v-if="allowedAction(['M020101', 'M020201', 'M020301', 'M020401', 'M020501'])"
    >
      <template v-slot:activator>
        <v-list-item-title>販売管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'TemporaryPurchaseSearch' }" v-if="allowedAction(['M020101'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>仮受注一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PurchaseSearch' }" v-if="allowedAction(['M020201'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>受注管理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PropositionSearch' }" v-if="allowedAction(['M020301'])">
        <v-list-item-icon><v-icon>mdi-folder-search</v-icon></v-list-item-icon>
        <v-list-item-title>委託・消化仕入処理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'DeficiencySearch' }" v-if="allowedAction(['M020401'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>不足納品処理</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'PurchaseReturnSearch' }" v-if="allowedAction(['M020501'])">
        <v-list-item-icon><v-icon>mdi-book-search</v-icon></v-list-item-icon>
        <v-list-item-title>返品処理</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
    <v-list-group no-action prepend-icon="mdi-account" v-if="allowedAction(['M030101'])">
      <template v-slot:activator>
        <v-list-item-title>ユーザ管理</v-list-item-title>
      </template>
      <v-list-item exact :to="{ name: 'UserSearch' }" v-if="allowedAction(['M030101'])">
        <v-list-item-icon><v-icon>mdi-account-search</v-icon></v-list-item-icon>
        <v-list-item-title>ユーザ一覧</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{ name: 'SupplierEdit' }" v-if="allowedAction(['M040106'])">
        <v-list-item-icon><v-icon>mdi-text-box-search</v-icon></v-list-item-icon>
        <v-list-item-title>取引先情報</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-divider></v-divider>
  </v-list>
</template>

<script>
export default {
  name: "MakerMenu",
};
</script>
