//import moment from "moment";
import * as Api from "./../../libs/api-client";

const state = {
  notices: {},
  num: 0,
  intervalId: null,
};

const getters = {
  latest(state) {
    const cloneNotices = JSON.parse(JSON.stringify(state.notices));
    if (Object.keys(state.notices).length > 0) {
      cloneNotices.generalNotifications.sort((a, b) => b.id - a.id);
      cloneNotices.seriousNotifications.sort((a, b) => b.id - a.id);
    }
    console.log("notice/latest", cloneNotices);
    return cloneNotices;
  },
  getNum(state) {
    return state.num;
  },
  getIntervalId(state) {
    return state.intervalId;
  },
};

const mutations = {
  mark(state, id) {
    for (let noticeName in state.notices) {
      let notice = state.notices[noticeName].find((n) => String(n.notificationId) === String(id));
      if (notice) {
        notice.readedFlag = true;
      }
    }
  },
  setNotices(state, noticesInfo) {
    console.log("notice/setNotices", noticesInfo);
    state.notices = noticesInfo;
  },
  setNum(state, num) {
    console.log("notice/setNum", num);
    state.num = num;
  },
  calcNum(state, value) {
    state.num += value;
  },
  setIntervalId(state, intervalId) {
    state.intervalId = intervalId;
  },
};

const actions = {
  async getNoticeInfo(_) {
    const noticesInfo = await Api.commonGet("/notification/search");
    console.log("notice/getNoticeInfo", noticesInfo);
    if (noticesInfo) {
      _.commit("setNotices", noticesInfo.data.contents);
    }
    return noticesInfo;
  },
  async getNoticeNum(_) {
    const num = await Api.commonGet("/notification/count");
    if (num) {
      _.commit("setNum", num.data.contents.count);
    }
    return;
  },
  async entryReadedNotice(_, condition) {
    console.log("notice/entryReadedNotice", condition);
    return await Api.commonPost("/notification/readed", condition);
  },
  clearNoticeInterval({ getters }) {
    console.log("notice/clearNoticeInterval", getters.getIntervalId);
    clearInterval(getters.getIntervalId);
    return;
  },
  async getNoticeSettingTree(_, condition) {
    return await Api.commonGet("/notification/search-setting-tree", condition);
  },
  async getNoticeSetting(_, condition) {
    return await Api.commonGet("/notification/search-setting", condition);
  },
  async updateNoticeetting(_, condition) {
    return await Api.commonPost("/notification/update-setting", condition);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
